import React from 'react'
import Layout from '@components/Layout'
import { Link } from 'gatsby'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { TitleParagraph } from '@global'

const CommunityIndex = () => (
  <Layout>
    <Seo title="Community Information | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="Community Information" />
    <TitleParagraph>Please select from one of the links below</TitleParagraph>
    <ul>
      <li>
        <Link to="/community/churches">Churches</Link>
      </li>
      <li>
        <Link to="/community/groups">Groups</Link>
      </li>
      <li>
        <Link to="/community/schools">Schools</Link>
      </li>
      <li>
        <Link to="/community/information">Information</Link>
      </li>
    </ul>
  </Layout>
)

export default CommunityIndex
